<template>
  <div>
    <div class="dialogs">
      <v-dialog
        overlay-color="black"
        overlay-opacity="0.8"
        v-model="declinedMailDialog"
        @keydown.esc="declinedMailDialog = false"
        persistent
        content-class="mailSendingDialog"
      >
        <v-row class="send--dialog--content">
          <v-col sm="8" class="left--side">
            <div>
              <div>
                <v-card-title style="font-size: 30px">
                  <h6>
                    Send email to

                    <span
                      class="red--text"
                      style="padding-right: 8px; padding-left: 8px"
                      >Declined</span
                    >
                    ({{ unnotifiedCandidates && unnotifiedCandidates.length }})
                  </h6>
                </v-card-title>
              </div>
              <div class="sentMailFullBox">
                <v-card class="textsAndButtonsAreaMail" style="padding: 10px">
                  <v-row style="flex-direction: column">
                    <div style="flex-direction: column">
                      <h2>Subject</h2>
                      <textarea
                        v-model="declineSubject"
                        value="declineSubject"
                        class="subjectInput"
                        style
                      ></textarea>
                    </div>
                    <v-row>
                      <v-col>
                        <v-card-title style="padding-top: 2%"
                          >Message</v-card-title
                        >
                        <textarea
                          @input="declinedMailPreview()"
                          rows="4"
                          v-model="rejectText"
                          label="Message"
                          value="rejectText"
                          id="decPre"
                          class="mailTextInput mailTextArea"
                        ></textarea>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row style="margin-top: 70px">
                    <v-col cols="6" class="copyActionBar d-flex">
                      <div>
                        <v-btn
                          class="nameBtn"
                          v-on:click="
                            insertAtCaret('decPre', '<CANDIDATE NAME>')
                          "
                          outlined
                          small
                        >
                          {{ candName }}
                        </v-btn>
                      </div>
                      <div>
                        <p>
                          Copy &#60;{{ "Candidate Name" }}&#62; tag to clipboard
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="6" class="dialogActionBar">
                      <message-preview
                        :text="rejectCopy"
                        :subject="
                          declineSubject.replace(
                            '<CANDIDATE NAME>',
                            firstCandidateName.name
                          )
                        "
                      />
                      <v-btn
                        rounded
                        color="#B8C5D3"
                        class="white--text"
                        v-on:click="
                          selfEmailSend({
                            text: rejectCopy,
                            subject: declineSubject.replace(
                              '<CANDIDATE NAME>',
                              firstCandidateName.name
                            ),
                          })
                        "
                        >Send test mail</v-btn
                      >
                      <v-btn
                        rounded
                        color="#2DCD7A"
                        class="white--text"
                        v-on:click="
                          currentDate();
                          rejectedMails();
                        "
                        >Send emails</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-col>
          <v-col sm="4" class="right--side">
            <v-btn
              class="mailClose"
              style="margin-left: 88%"
              color="darken-1"
              text
              @click="declinedMailDialog = false"
              right
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="sendToCol" cols="3">
              <h5>Send To DECLINED candidates</h5>
              <send-to-mail
                :candidates="unnotifiedCandidates"
                @updateParent="updateValue"
              />
            </div>
          </v-col>
        </v-row>
      </v-dialog>
      <v-dialog
        overlay-color="black"
        overlay-opacity="0.8"
        v-model="acceptedMailDialog"
        @keydown.esc="acceptedMailDialog = false"
        persistent
        content-class="mailSendingDialog"
      >
        <v-row class="send--dialog--content">
          <v-col sm="8" class="left--side">
            <div>
              <div>
                <v-card-title style="font-size: 30px">
                  <h6>
                    Send email to
                    <span
                      class="green--text"
                      style="padding-right: 8px; padding-left: 8px"
                      >Accepted</span
                    >
                    ({{ unnotifiedCandidates && unnotifiedCandidates.length }})
                  </h6>
                </v-card-title>
              </div>
              <div class="sentMailFullBox">
                <v-card class="textsAndButtonsAreaMail" style="padding: 10px">
                  <v-row style="flex-direction: column">
                    <div style="flex-direction: column">
                      <h2>Subject</h2>
                      <textarea
                        v-model="acceptSubject"
                        value="acceptSubject"
                        class="subjectInput"
                        style
                      ></textarea>
                    </div>
                    <v-row>
                      <v-col>
                        <v-card-title style="padding-top: 2%"
                          >Message</v-card-title
                        >
                        <textarea
                          @input="acceptedMailPreview()"
                          rows="4"
                          v-model="acceptText"
                          label="Message"
                          value="acceptText"
                          id="decPre"
                          class="mailTextInput mailTextArea"
                        ></textarea>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row style="margin-top: 70px">
                    <v-col cols="6" class="copyActionBar d-flex">
                      <div>
                        <v-btn
                          class="nameBtn"
                          v-on:click="
                            insertAtCaret('decPre', '<CANDIDATE NAME>')
                          "
                          outlined
                          small
                        >
                          {{ candName }}
                        </v-btn>
                      </div>
                      <div>
                        <p>
                          Copy &#60;{{ "Candidate Name" }}&#62; tag to clipboard
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="6" class="dialogActionBar">
                      <message-preview
                        :text="acceptCopy"
                        :subject="
                          acceptSubject &&
                          acceptSubject.replace(
                            '<CANDIDATE NAME>',
                            firstCandidateName.name
                          )
                        "
                      />
                      <v-btn
                        rounded
                        color="#B8C5D3"
                        class="white--text"
                        v-on:click="
                          selfEmailSend({
                            text: acceptCopy,
                            subject:
                              acceptSubject &&
                              acceptSubject.replace(
                                '<CANDIDATE NAME>',
                                firstCandidateName.name
                              ),
                          })
                        "
                        >Send test mail</v-btn
                      >
                      <v-btn
                        rounded
                        color="#2DCD7A"
                        class="white--text"
                        v-on:click="
                          currentDate();
                          acceptedMails();
                        "
                        >Send emails</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-col>
          <v-col sm="4" class="right--side">
            <v-btn
              class="mailClose"
              style="margin-left: 88%"
              color="darken-1"
              text
              @click="acceptedMailDialog = false"
              right
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="sendToCol" cols="3">
              <h5>Send To ACCEPTED candidates</h5>
              <send-to-mail
                @updateParent="updateValue"
                :candidates="unnotifiedCandidates"
              />
            </div>
          </v-col>
        </v-row>
      </v-dialog>
      <v-dialog
        overlay-color="black"
        overlay-opacity="0.8"
        v-model="interviewedDialog"
        @keydown.esc="interviewedDialog = false"
        persistent
        content-class="mailSendingDialog"
      >
        <v-row class="send--dialog--content">
          <v-col sm="8" class="left--side">
            <div>
              <div>
                <v-card-title style="font-size: 30px">
                  <h6>
                    Send email to
                    <span
                      class="blue--text"
                      style="padding-right: 8px; padding-left: 8px"
                      >Interviewed</span
                    >
                    ({{ unnotifiedCandidates && unnotifiedCandidates.length }})
                  </h6>
                </v-card-title>
              </div>
              <div class="sentMailFullBox">
                <v-card class="textsAndButtonsAreaMail" style="padding: 10px">
                  <v-row style="flex-direction: column">
                    <div>
                      <h2>Subject</h2>
                      <textarea
                        v-model="interviewedSubject"
                        value="interviewedSubject"
                        class="subjectInput"
                        style
                      ></textarea>
                    </div>
                    <v-row>
                      <v-col>
                        <v-card-title style="padding-top: 2%"
                          >Message</v-card-title
                        >
                        <textarea
                          @input="interviewedMailPreview()"
                          rows="4"
                          v-model="interviewedText"
                          label="Message"
                          value="interviewedText"
                          id="decPre"
                          class="mailTextInput mailTextArea"
                        ></textarea>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row style="margin-top: 70px">
                    <v-col cols="6" class="copyActionBar d-flex">
                      <div>
                        <v-btn
                          class="nameBtn"
                          v-on:click="
                            insertAtCaret('decPre', '<CANDIDATE NAME>')
                          "
                          outlined
                          small
                        >
                          {{ candName }}
                        </v-btn>
                      </div>

                      <p>
                        Copy &#60;{{ "Candidate Name" }}&#62; tag to clipboard
                      </p>
                      <div></div>
                    </v-col>
                    <v-col cols="6" class="dialogActionBar">
                      <message-preview
                        :text="interviewedCopy"
                        :subject="
                          interviewedSubject.replace(
                            '<CANDIDATE NAME>',
                            firstCandidateName.name
                          )
                        "
                      />
                      <v-btn
                        rounded
                        color="#B8C5D3"
                        class="white--text"
                        v-on:click="
                          selfEmailSend({
                            text: interviewedCopy,
                            subject: interviewedSubject.replace(
                              '<CANDIDATE NAME>',
                              firstCandidateName.name
                            ),
                          })
                        "
                        >Send test mail</v-btn
                      >
                      <v-btn
                        rounded
                        v-on:click="
                          currentDate();
                          interviewedMails();
                        "
                        color="#2DCD7A"
                        class="white--text"
                        >Send emails</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-col>
          <v-col sm="4" class="right--side">
            <v-btn
              class="mailClose"
              style="margin-left: 88%"
              color="darken-1"
              text
              @click="interviewedDialog = false"
              right
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="sendToCol" cols="3">
              <h5>Send To ACCEPTED candidates</h5>
              <send-to-mail
                @updateParent="updateValue"
                :candidates="unnotifiedCandidates"
              />
            </div>
          </v-col>
        </v-row>
      </v-dialog>

      <v-dialog
        overlay-color="black"
        overlay-opacity="0.8"
        v-model="emailDialog"
        @keydown.esc="emailDialog = false"
        persistent
        max-width="512"
        content-class="mailDialogWithBell"
        hide-overlay
      >
        <v-card
          color="rgba(255,255,255, 0.8)"
          class="select-cand-card"
          height="450"
        >
          <div class="content--cand--card">
            <v-btn icon @click="emailDialog = false" small class="close--btn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div
              class="text-center d-flex align-center justify-center"
              style="flex-direction: column"
            >
              <v-card-title
                class="headline text-center"
                style="padding-top: 25%"
              >
                Select Candidate List
              </v-card-title>
              <v-select
                rounded
                :items="emailOptions"
                v-model="emailSelected"
                filled
                label="Choose list.."
                dense
                width="255"
                class="candidates--v--select"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn
                width="255"
                rounded
                small
                @click="
                  openChosenEmailDialog(emailSelected);
                  emailDialog = false;
                "
                height="50"
                class="v-btn v-btn--contained theme--dark v-size--default primary ma-2 purpleFade mailContinue"
              >
                Continue
              </v-btn>
            </div>
          </div>
          <div class="bg--bell--icon">
            <img src="@/assets/right-side-bell.png" />
          </div>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="downloadDialog"
        @keydown.esc="downloadDialog = false"
        persistent
        max-width="512"
        content-class="mailDialogWithBell"
        hide-overlay
        overlay-color="black"
        overlay-opacity="0.8"
      >
        <v-card color="rgba(255,255,255, 0.8)" class="select-cand-card">
          <v-btn icon @click="downloadDialog = false" small class="close--btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="mailSentSelectBox">
            <div class="content--cand--card">
              <v-card-title class="headline">
                <p>Select Candidate List</p>
              </v-card-title>
              <v-select
                :items="downloadOptions"
                v-model="downloadSelected"
                filled
                label="Choose list.."
                dense
                style="max-width: 50%; margin: 0 auto"
                rounded
              ></v-select>
              <v-card-actions>
                <div class="excelDownloadPositions">
                  <div>
                    <download-excel
                      class="btn btn-default excelActionButton"
                      :data="excelData"
                      worksheet="Kandidaadid"
                      name="levelup.xls"
                    >
                      <v-btn
                        @click="
                          downloadXls(downloadSelected);
                          downloadDialog = false;
                          startAnimation(4);
                        "
                        height="50"
                        width="256"
                        rounded
                        class="v-btn v-btn--contained theme--dark v-size--default ma-2 purpleFade mailContinue"
                      >
                        Download xls
                      </v-btn>
                    </download-excel>
                  </div>
                  <div>
                    <download-excel
                      class="btn btn-default excelActionButton"
                      :data="excelData"
                      worksheet="Kandidaadid"
                      name="levelup.csv"
                      type="csv"
                    >
                      <v-btn
                        @click="
                          downloadXls(downloadSelected);
                          downloadDialog = false;
                          startAnimation(4);
                        "
                        height="50"
                        width="256"
                        rounded
                        class="v-btn v-btn--contained theme--dark v-size--default ma-2 purpleFade mailContinue"
                      >
                        Download csv
                      </v-btn>
                    </download-excel>
                  </div>
                </div>
              </v-card-actions>
            </div>
          </div>
          <div class="bg--bell--icon">
            <img src="@/assets/right-side-bell.png" />
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div class="buttons" v-scroll="handleScroll">
      <v-btn
        class="mailAndDownloadBtn mr-4"
        @click="
          emailDialog = true;
          downloadDialog = false;
        "
        rounded
        large
      >
        <v-icon>mdi-email-outline</v-icon>
        <span>Send emails</span>
      </v-btn>

      <v-btn
        class="mailAndDownloadBtn"
        @click="
          downloadDialog = true;
          emailDialog = false;
        "
        rounded
        large
      >
        <v-icon>mdi-microsoft-excel</v-icon>
        <span>Download</span>
      </v-btn>
    </div>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackText }}
      <v-btn
        color="blue"
        text
        @click="
          revertMails();
          snackbar = false;
        "
        >CANCEL EMAILS {{ countDown }}s</v-btn
      >
    </v-snackbar>

    <v-snackbar v-model="snackbarSuccess" :timeout="5000">
      {{ textSuccess }}
      <v-btn color="success" text @click="snackbarSuccess = false">Close</v-btn>
    </v-snackbar>

    <v-snackbar
      class="snackDecline"
      v-model="snackbarDeclining"
      :timeout="2000"
      :bottom="true"
    >
      <v-row style="padding-right: 0px">
        <v-col class="snackCol" align="left" style="font-size: 15px"
          >Candidate</v-col
        >
        <v-col
          class="snackCol2"
          align="right"
          style="color: #ff5050; font-size: 13px"
          >DECLINED</v-col
        >
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapState } from "vuex";
import SendToMail from "./SendToMail";
import MessagePreview from "./MessagePreview.vue";
import Vue from "vue";

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

export default {
  name: "CandidatesActions",
  components: { SendToMail, MessagePreview },
  data: () => ({
    timeout: 5000,
    countDown: 5,
    snackbarDeclining: false,
    snackbarSuccess: false,
    snackbarAccepting: false,
    snackText: "Sending emails...",
    textSuccess: "Emails sent!",
    snackbar: false,
    acceptedCounter: null,
    declinedCounter: null,
    emailDialog: false,
    downloadDialog: false,
    downloadSelected: "",
    downloadOptions: [
      "All candidates",
      "Accepted candidates",
      "Declined candidates",
      "Interviewed candidates",
    ],
    emailOptions: [
      "Accepted candidates",
      "Declined candidates",
      "Interviewed candidates",
    ],
    emailSelected: "",
    unnotifiedAccepted: null,
    unnotifiedCandidates: [],
    unnotifiedAcceptedEmails: [],
    unnotifiedDeclined: null,
    unnotifiedDeclinedEmails: [],
    acceptSubject: `🎉 Congratulations, you have advanced to the next round!`,
    declineSubject: `Thank you for applying!`,
    acceptCopy: null,
    acceptText: `Thank you, <CANDIDATE NAME>, for applying for the position. We are happy to inform you that you have advanced to the next round.`,
    rejectCopy: null,
    rejectText: `Thank you for applying, <CANDIDATE NAME>. Unfortunately we must inform you that this time you were not selected for the position.`,
    candName: "<CANDIDATE NAME>",
    campName: "<CAMPAIGN NAME>",
    acceptedMailDialog: false,
    declinedMailDialog: false,
    allTimeouts: [],
    interviewedCopy: null,
    interviewedText: `Thank you for applying, <CANDIDATE NAME>. You got to the interview phease.`,
    interviewedSubject: `🎉 Congratulations, you have advanced to the next round!`,
    interviewedAccepted: null,
    unnotifiedInterviewedEmails: [],
    interviewedDialog: false,
  }),
  mounted() {
    this.setMailData();
  },
  computed: {
    ...mapGetters([
      "acceptedCandidates",
      "declinedCandidates",
      "emailTemplate",
      "interviewedCandidatesMail",
      "allTemplates",
    ]),
    ...mapState(["excelData"]),
    firstCandidateName() {
      const data = this.unnotifiedCandidates.length
        ? this.unnotifiedCandidates[0].answers[0].answerText
        : "";
      return { name: data };
    },
  },
  methods: {
    ...mapActions([
      "downloadXls",
      "setError",
      "startAnimation",
      "fetchCandidates",
      "fetchCandidates2",
      "selfEmailSend",
      "updateTemplate",
      "candidateNotifiedUpdate",
    ]),
    handleScroll: function () {
      if (window.scrollY > 50) {
        document.querySelector(".buttons").style.cssText +=
          "top:100px;z-index:7;";
      } else {
        document.querySelector(".buttons").style.top = "150px";
      }
    },
    async openChosenEmailDialog(emailSelected) {
      const tempData = await axios.get(
        `/api/user/template/get/${this.$store.state.user.email}`
      );

      if (emailSelected == "Accepted candidates") {
        this.acceptedMailButton();
        if (tempData.data.acceptedTemplate) {
          this.acceptText =
            tempData.data.acceptedTemplate.acceptedText || this.acceptText;
          this.acceptSubject =
            tempData.data.acceptedTemplate.acceptedSubject ||
            this.acceptSubject;
        }
        this.acceptedMailDialog = true;
      } else if (emailSelected == "Declined candidates") {
        this.declinedMailButton();
        if (tempData.data.declinedTemp) {
          this.rejectText =
            tempData.data.declinedTemp.declinedText || this.rejectText;
          this.declineSubject =
            tempData.data.declinedTemp.declinedSubject || this.declineSubject;
        }
        this.declinedMailDialog = true;
      } else if (emailSelected == "Interviewed candidates") {
        this.InterviewedMailButton();
        if (tempData.data.interviewedTemplate) {
          this.interviewedText =
            tempData.data.interviewedTemplate.interviewedText ||
            this.interviewedText;
          this.interviewedSubject =
            tempData.data.interviewedTemplate.interviewedSubject ||
            this.interviewedSubject;
        }
        this.interviewedDialog = true;
      } else {
        this.setError("No list chosen");
      }
    },
    updateValue(value) {
      const { val, candId } = value;
      this.unnotifiedCandidates.forEach((cand) => {
        if (cand.id === candId) {
          cand.statusChecked = val;
        }
      });
    },
    handleChange(e) {
      const { value } = e;
      this.rejectText = value;
    },
    currentDate() {
      var d = new Date();
      var curr_date = d.getDate();
      var curr_month = d.getMonth() + 1; //Esimene kuu on 0
      var curr_year = d.getFullYear();
      var curr_hour = d.getHours();
      var curr_min = d.getMinutes();
      this.notifiedDate =
        curr_date +
        "." +
        curr_month +
        "." +
        curr_year +
        " " +
        curr_hour +
        ":" +
        curr_min;
    },

    insertAtCaret(areaId, text) {
      var txtarea = document.getElementById(areaId);
      var scrollPos = txtarea.scrollTop;
      var caretPos = txtarea.selectionStart;

      var front = txtarea.value.substring(0, caretPos);
      var back = txtarea.value.substring(
        txtarea.selectionEnd,
        txtarea.value.length
      );
      txtarea.value = front + text + back;
      caretPos = caretPos + text.length;
      txtarea.selectionStart = caretPos;
      txtarea.selectionEnd = caretPos;
      txtarea.focus();
      txtarea.scrollTop = scrollPos;
    },
    setMailData() {
      this.rejectCopy = this.rejectText;
    },
    celebrateAccepting() {
      this.snackbarAccepting = true;
    },
    celebrateDeclining() {
      this.snackbarDeclining = true;
    },
    countDownTimer() {
      let time1 = null;
      if (this.countDown > 0) {
        const counter = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
        time1 = counter;
      }
      if (this.countDown === 0) {
        this.countDown = 5;
        this.snackbarSuccess = true;
      }
      this.time = time1;
    },
    async revertMails() {
      try {
        this.countDown = 5;
        clearTimeout(this.time);
        clearTimeout(this.refresh);
        axios.post("/api/user/mail/cancel", {
          timeouts: this.allTimeouts,
        });
      } catch (error) {
        this.feedback = error;
      }
    },
    async InterviewedMailButton() {
      this.unnotifiedCandidates = this.interviewedCandidatesMail.filter(
        (c) => !c.notified
      );

      this.acceptedCounter = this.interviewedCandidatesMail;
      let kandidaadinimi = "";
      let kandidaadimeil = [];
      let kampaania1 = "";
      this.unnotifiedCandidates.forEach((e) => {
        let kandidaadinimed = e.answers[0].answerText;
        let kampaania = e.formName;
        let kandidaadimeilid = e.answers[1].answerText;
        kandidaadinimi = kandidaadinimed;
        kandidaadimeil.push(kandidaadimeilid);
        kampaania1 = kampaania;
      });
      this.unnotifiedInterviewed = kandidaadinimi;
      this.unnotifiedInterviewedEmails = kandidaadimeil;
      this.currentCampaign = kampaania1;
      this.interviewedMailPreview();
    },
    interviewedMailPreview() {
      this.interviewedCopy = this.interviewedText;
      this.interviewedCopy = this.interviewedCopy
        .split("<CANDIDATE NAME>")
        .join(this.unnotifiedInterviewed);
      this.interviewedCopy = this.interviewedCopy
        .split("<CAMPAIGN NAME>")
        .join(this.currentCampaign);
    },
    async acceptedMailButton() {
      this.unnotifiedCandidates = this.acceptedCandidates.filter(
        (c) => !c.notified
      );

      this.acceptedCounter = this.acceptedCandidates;
      let kandidaadinimi = "";
      let kandidaadimeil = [];
      let kampaania1 = "";
      this.unnotifiedCandidates.forEach((e) => {
        let kandidaadinimed = e.answers[0].answerText;
        let kampaania = e.formName;
        let kandidaadimeilid = e.answers[1].answerText;
        kandidaadinimi = kandidaadinimed;
        kandidaadimeil.push(kandidaadimeilid);
        kampaania1 = kampaania;
      });
      this.unnotifiedAccepted = kandidaadinimi;
      this.unnotifiedAcceptedEmails = kandidaadimeil;
      this.currentCampaign = kampaania1;
      this.acceptedMailPreview();
    },
    acceptedMailPreview() {
      this.acceptCopy = this.acceptText;
      this.acceptCopy = this.acceptCopy
        .split("<CANDIDATE NAME>")
        .join(this.unnotifiedAccepted);
      this.acceptCopy = this.acceptCopy
        .split("<CAMPAIGN NAME>")
        .join(this.currentCampaign);
    },
    async interviewedMails() {
      if (this.unnotifiedCandidates.length === 0) {
        this.setError("No unnotified candidates in accepted section");
      }
      try {
        let emailCount = null;
        if (this.emailCounts > 0) {
          emailCount = this.emailCounts;
        } else {
          emailCount = 0;
        }
        emailCount += 1;
        this.$store.state.user.emailCounts += 1;
        if (this.emailCounts % 5 === 0) {
          this.eCounter = 1;
        } else if (this.emailCounts === 1) {
          this.eCounter = 1;
        } else {
          this.eCounter = 0;
        }
        let mailCount = {
          email: this.$store.state.user.email,
          emailCounts: emailCount,
        };
        let pattern = "<CANDIDATE NAME>";
        let pattern2 = "<CAMPAIGN NAME>";
        let re = new RegExp(pattern, "g");
        let re2 = new RegExp(pattern2, "g");
        const candidateIds = [];
        this.unnotifiedCandidates
          .filter((cand) => cand.checkStatus === true)
          .forEach((e) => {
            let subject = this.interviewedSubject;
            const acceptedTimeouts = [];
            this.allTimeouts.push(acceptedTimeouts);
            const kandidaadinimed = e.answers[0].answerText;
            const acceptedRecipents = e.answers[1].answerText;
            let readyText = this.interviewedText;
            readyText = readyText.replace(re, `${kandidaadinimed}`);
            readyText = readyText.replace(re2, `${e.formName}`);
            const mailOptions = {
              to: acceptedRecipents,
              text:
                readyText + ` \n\nReply to: ${this.$store.state.user.email}`,
              subject: subject.replace(re, `${kandidaadinimed}`),
            };
            axios
              .post("/api/user/mail", mailOptions)
              .then((response) => {
                acceptedTimeouts.push(response.data);
              })
              .catch(() =>
                this.setError(`Failed to send mail to ${kandidaadinimed}`)
              );
            axios
              .put(`/api/form/notified/${e._id}`, { notified: true })
              .then((response) => {
                acceptedTimeouts.push(response.data);
              });
            axios
              .put(`/api/form/notifiedDate/${e._id}`, {
                notifiedAt: this.notifiedDate,
              })
              .then((response) => {
                acceptedTimeouts.push(response.data);
              });
            axios.post("/api/form/addMessage/" + e._id, {
              author:
                this.$store.state.user.fullName || this.$store.state.user.email,
              message: `Mail sent: ${new Date().toLocaleString()}, candidate interviewed`,
            });
            candidateIds.push(e._id);
          });
        this.updateTemplate({
          subject: this.interviewedSubject,
          text: this.interviewedText,
          type: "interviewed",
        });

        axios.post("/api/user/mailc/", mailCount);
        if (this.unnotifiedCandidates.length > 0) {
          this.countDownTimer();
          this.snackbar = true;
        }
        this.interviewedDialog = false;
        this.refresh = setTimeout(async () => {
          //const response = await axios.get(`/api/form/${this.campaignId}`);
          //this.candidates = response.data;
          //this.loadCandidates();
          this.candidateNotifiedUpdate(candidateIds);
        }, 5000);
      } catch (error) {
        this.setError("Error occured while sending emails to candidates!");
      }
    },
    async acceptedMails() {
      if (this.unnotifiedCandidates.length === 0) {
        this.setError("No unnotified candidates in accepted section");
      }
      try {
        let emailCount = null;
        if (this.emailCounts > 0) {
          emailCount = this.emailCounts;
        } else {
          emailCount = 0;
        }
        emailCount += 1;
        this.$store.state.user.emailCounts += 1;
        if (this.emailCounts % 5 === 0) {
          this.eCounter = 1;
        } else if (this.emailCounts === 1) {
          this.eCounter = 1;
        } else {
          this.eCounter = 0;
        }
        let mailCount = {
          email: this.$store.state.user.email,
          emailCounts: emailCount,
        };
        let pattern = "<CANDIDATE NAME>";
        let pattern2 = "<CAMPAIGN NAME>";
        let re = new RegExp(pattern, "g");
        let re2 = new RegExp(pattern2, "g");
        const candidateIds = [];
        this.unnotifiedCandidates
          .filter((cand) => cand.checkStatus === true)
          .forEach((e) => {
            let subject = this.acceptSubject;
            const acceptedTimeouts = [];
            this.allTimeouts.push(acceptedTimeouts);
            const kandidaadinimed = e.answers[0].answerText;
            const acceptedRecipents = e.answers[1].answerText;
            let readyText = this.acceptText;
            readyText = readyText.replace(re, `${kandidaadinimed}`);
            readyText = readyText.replace(re2, `${e.formName}`);
            const mailOptions = {
              to: acceptedRecipents,
              text:
                readyText + ` \n\nReply to: ${this.$store.state.user.email}`,
              subject: subject.replace(re, kandidaadinimed),
            };
            axios
              .post("/api/user/mail", mailOptions)
              .then((response) => {
                acceptedTimeouts.push(response.data);
              })
              .catch(() =>
                this.setError(`Failed to send mail to ${kandidaadinimed}`)
              );
            axios
              .put(`/api/form/notified/${e._id}`, { notified: true })
              .then((response) => {
                acceptedTimeouts.push(response.data);
              });
            axios
              .put(`/api/form/notifiedDate/${e._id}`, {
                notifiedAt: this.notifiedDate,
              })
              .then((response) => {
                acceptedTimeouts.push(response.data);
              });
            axios.post("/api/form/addMessage/" + e._id, {
              author:
                this.$store.state.user.fullName || this.$store.state.user.email,
              message: `Mail sent: ${new Date().toLocaleString()}, candidate accepted`,
            });
            candidateIds.push(e._id);
          });

        this.updateTemplate({
          subject: this.acceptSubject,
          text: this.acceptText,
          type: "accepted",
        });

        axios.post("/api/user/mailc/", mailCount);
        if (this.unnotifiedCandidates.length > 0) {
          this.countDownTimer();
          this.snackbar = true;
        }
        this.acceptedMailDialog = false;
        this.refresh = setTimeout(async () => {
          //const response = await axios.get(`/api/form/${this.campaignId}`);
          //this.candidates = response.data;
          //this.loadCandidates();
          this.candidateNotifiedUpdate(candidateIds);
        }, 5000);
      } catch (error) {
        this.setError("Error occured while sending emails to candidates!");
      }
    },

    async declinedMailButton() {
      this.unnotifiedCandidates = this.declinedCandidates.filter(
        (c) => !c.notified
      );
      this.declinedCounter = this.declinedCandidates;
      let kandidaadinimi = "";
      let kandidaadimeil = [];
      let kampaania1 = "";
      this.unnotifiedCandidates.forEach((e, i) => {
        let kandidaadinimed = e.answers[i].answerText;
        let kandidaadimeilid = e.answers[i].answerText;
        let kampaania = e.formName;
        e.checked = true;
        kandidaadinimi = kandidaadinimed;
        kandidaadimeil.push(kandidaadimeilid);
        kampaania1 = kampaania;
      });
      this.unnotifiedDeclined = kandidaadinimi;
      this.unnotifiedDeclinedEmails = kandidaadimeil;
      this.currentCampaign = kampaania1;
      this.declinedMailPreview();
    },
    declinedMailPreview() {
      this.rejectCopy = this.rejectText;
      this.rejectCopy = this.rejectCopy
        .split("<CANDIDATE NAME>")
        .join(this.unnotifiedDeclined);
      this.rejectCopy = this.rejectCopy
        .split("<CAMPAIGN NAME>")
        .join(this.currentCampaign);
    },
    removeAccepted(index) {
      this.unnotifiedCandidates.splice(index, 1);
      this.acceptedCounter = this.unnotifiedCandidates;
    },
    removeDeclined(index) {
      this.unnotifiedCandidates.splice(index, 1);
      this.declinedCounter = this.unnotifiedCandidates;
    },
    loadCandidates() {
      this.fetchCandidates(this.$route.params.id || this.defaultCampaign);
      this.fetchCandidates2(this.$route.params.id || this.defaultCampaign);
    },
    async rejectedMails() {
      if (this.unnotifiedCandidates.length === 0) {
        this.setError("No unnotified candidates in declined section");
      }
      try {
        let pattern = "<CANDIDATE NAME>";
        let pattern2 = "<CAMPAIGN NAME>";
        let re = new RegExp(pattern, "g");
        let re2 = new RegExp(pattern2, "g");
        const candidateIds = [];
        this.unnotifiedCandidates
          .filter((cand) => cand.checkStatus === true)
          .forEach((e) => {
            let subject = this.declineSubject;
            const declinedTimeouts = [];
            this.allTimeouts.push(declinedTimeouts);
            const kandidaadimeilid = e.answers[1].answerText;
            const kandidaadinimed = e.answers[0].answerText;
            let readyText = this.rejectText;
            readyText = readyText.replace(re, `${kandidaadinimed}`);
            readyText = readyText.replace(re2, `${e.formName}`);
            const mailOptions = {
              to: kandidaadimeilid,
              text: readyText,
              subject: subject.replace(re, kandidaadinimed),
            };
            axios
              .post("/api/user/mail", mailOptions)
              .then((response) => {
                declinedTimeouts.push(response.data);
              })
              .catch(() =>
                this.setError(`Failed to send mail to ${kandidaadinimed}`)
              );
            axios
              .put(`/api/form/notified/${e._id}`, { notified: true })
              .then((response) => {
                declinedTimeouts.push(response.data);
              });
            axios
              .put(`/api/form/notifiedDate/${e._id}`, {
                notifiedAt: this.notifiedDate,
              })
              .then((response) => {
                declinedTimeouts.push(response.data);
              });

            axios.post("/api/form/addMessage/" + e._id, {
              author:
                this.$store.state.user.fullName || this.$store.state.user.email,
              message: `Mail sent: ${new Date().toLocaleString()}, candidate declined`,
            });
            candidateIds.push(e._id);
          });
        this.updateTemplate({
          subject: this.declineSubject,
          text: this.rejectText,
          type: "declined",
        });

        // let emailTemp2 = {
        //  email: this.$store.state.user.email,
        //  declinedSubject: this.declineSubject,
        //  rejectText: this.rejectText,
        //};
        //axios.put("/api/user/mail/declined", emailTemp2).then((response) => {
        //  console.log(response);
        //});
        if (this.unnotifiedCandidates.length > 0) {
          this.countDownTimer();
          this.snackbar = true;
        }
        this.declinedMailDialog = false;
        this.refresh = setTimeout(async () => {
          //const response = await axios.get(`/api/form/${this.campaignId}`);
          //this.candidates = response.data;
          //this.loadCandidates();
          this.candidateNotifiedUpdate(candidateIds);
        }, 5000);
      } catch (error) {
        this.setError("Error occured while sending emails to candidates!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog:not(.v-dialog--fullscreen) {
  height: 90%;
}
.buttons {
  justify-content: right;
  display: flex;
  position: fixed;
  top: 150px;
  //z-index: 7;

  right: 2.1rem;
  .v-btn {
  }
}
.left--side {
  background: #f2f0f0;
  padding: 40px;
}
.right--side {
  background: #ffffff;
}
.mailTextArea {
  background-color: #ffffff !important;
}
.textsAndButtonsAreaMail {
  background-color: transparent !important;
  border-radius: 10px;
  height: 100%;
  max-height: 550px;
}
.v-card__title {
  font-weight: bold;
  font-size: 32px;
}

.close--btn {
  position: absolute;
  right: 0;
}
.select-cand-card {
  backdrop-filter: blur(5px);
  .headline {
    color: #666666;
  }
}
.candidates--v--select {
  max-width: 255px;
}

.send--dialog--content {
  height: 100% !important;
}
.bg--bell--icon {
  position: absolute;
  top: -75px;
  left: -75px;
  img {
    max-width: 150px;
  }
}
.excelDownloadPositions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mailContinue {
  border-radius: 40px;
  font-weight: 900;
  font-size: 20px;
}
.mailSentSelectBox {
  padding-top: 50px;
  padding-bottom: 50px;
  .v-card__actions {
    justify-content: center;
  }
}
.headline {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.subjectInput {
  border-radius: 7px;
  background-color: #fff;
  float: left;
  width: 100%;
  outline: none;
  resize: none;
  padding: 20px;
  border-radius: 10px;
  max-height: 550px;
  height: 65px;
}
.subjectBigArea {
  min-height: 300px;
}
.mailAndDownloadBtn {
  background: #fff !important;
  color: #666666;
  span {
    text-transform: capitalize;
  }
}
.dialogActionBar {
  justify-content: space-around;
  display: flex;
  .v-btn {
    text-transform: capitalize;
  }
}
.copyActionBar {
  display: flex;
  flex-direction: column;
  p {
    font: 12px normal medium 10px/42px;
    color: #666666;
  }
}
.textsAndButtonsAreaMail {
  .v-card__title {
    padding: 0 0 5px 0;
  }
  h2,
  .v-card__title {
    color: #666666;
    font-weight: 700;
    font-size: 18px;
  }
}
.v-card__title {
  h6 {
    font-weight: 700;
    font-size: 32px;
    color: #666666;
  }
}
</style>
