<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="previewBtn" color="#F7F6F6" rounded v-bind="attrs" v-on="on"
        >Preview</v-btn
      >
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          color="linear-gradient(229deg, #604FE2 0%, #772276 100%)"
          dark
          >MESSAGE PREVIEW:</v-toolbar
        >
        <v-card-text>
          <div class="text-h2 pa-12">
            <textarea
              v-model="mainText.title"
              class="mailTextInput mailTextArea"
              value="declineSubject"
              readonly="true"
            ></textarea>
            <textarea
              v-model="mainText.content"
              value="rejectCopy"
              readonly="true"
              rows="4"
              class="mailTextInput mailTextArea"
            ></textarea>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "MessagePreview",
  props: {
    text: String,
    subject: String,
  },
  computed: {
    mainText() {
      return { title: this.subject, content: this.text };
    },
  },
};
</script>

<style lang="scss" scoped>
.previewBtn {
  text-transform: capitalize;
  color: #b4c1cf;
}
.mailTextArea {
  height: auto;
}
.mailTextInput {
  font-size: 16px;
  line-height: normal;
}
</style>
