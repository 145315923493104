<template>
  <div>
    <v-row class="candidate--row" justify="center" align="center">
      <v-col cols="2" class="checkbox--col">
        <p>&nbsp;</p>
      </v-col>
      <v-col cols="5" class="name--row">
        <p class="selectTitleText">
          Name
        </p>
      </v-col>
      <v-col cols="5" class="email--row">
        <p class="selectTitleText">
          Email
        </p>
      </v-col>
    </v-row>
    <v-row
      class="candidate--row"
      justify="center"
      align="center"
      v-for="(candidate, index) in candidates"
      :key="index"
    >
      <v-col cols="2" class="checkbox--col align-center justify-end">
        <v-checkbox
          hide-details
          class="align-center justify-center"
          dense
          color="#000"
          v-model="candidate.checkStatus"
          @change="(e) => change(e, candidate._id)"
        ></v-checkbox>
      </v-col>
      <v-col cols="5" class="name--row">
        <p>
          {{ candidate.answers[0].answerText }}
        </p>
      </v-col>
      <v-col cols="5" class="email--row">
        <p>
          {{ candidate.answers[1].answerText }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SendToMail",
  data() {
    return {};
  },
  props: {
    candidates: Array,
  },
  methods: {
    change(e, id) {
      this.$emit("updateParent", { val: e, candId: id });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox--col {
  padding-top: 0;
  padding-bottom: 0;
}
.candidate--row:nth-child(1) {
  background-color: #fff;
}
.email--row {
  background-color: #f2f0f0;
  height: 100%;
  overflow-y: auto;
}
.name--row {
  border-bottom: #f2f0f0 solid 1px;
  overflow-y: auto;
}
.v-input--selection-controls.v-input {
  margin-top: 0;
}
.selectTitleText {
  font-weight: bold;
}
</style>
