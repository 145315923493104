<template>
  <v-container fluid v-scroll="handleScroll">
    <div class="action-background"></div>
    <dashboard-nav />
    <candidates-actions />
    <animation></animation>

    <!-- <loading v-if="fetching"></loading> -->
    <v-progress-linear
      color="deep-purple accent-4 tp-6"
      indeterminate
      rounded
      height="6"
      v-if="fetching"
      class="ma-4"
    ></v-progress-linear>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class=""
      mode="out-in"
      v-else
    >
      <router-view />
    </transition>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
import anchorme from "anchorme";
import DashboardNav from "../components/DashboardNav.vue";
import CandidatesActions from "../components/CandidatesActions.vue";
import Animation from "../components/Animation.vue";

export default {
  name: "FilterTabs",
  components: {
    DashboardNav,
    CandidatesActions,
    Animation,
  },
  data() {
    return {
      timer: "",
    };
  },
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$router.push("/analytics");
    }
    this.timer = setInterval(this.loadCandidates(), 300000);
  },
  mounted() {
    this.loadCandidates();
  },
  watch: {
    $route() {
      this.loadCandidates();
    },
  },
  updated() {
    this.$nextTick(() => {
      this.commentLinks();
    });
  },
  computed: {
    ...mapGetters([
      "acceptedCandidates",
      "acceptedCounts",
      "acceptedExcelCandidates",
      "declinedExcelCandidates",
      "acceptedNotifiedCandidates",
      "declinedCandidates",
      "declinedNotifiedCandidates",
      "undecidedCandidates",
      "isUserAdmin",
      "defaultCampaign",
      "emailTemplate",
      "campaigns",
      "ratingCounts",
      "emailCounts",
    ]),
    ...mapState(["fetching", "excelData"]),
  },
  methods: {
    ...mapActions([
      "fetchCandidates",
      "fetchCandidates2",
      "deleteCandidate",
      "changeCandidateStatus",
      "downloadXls",
    ]),
    handleScroll: function() {
      if (window.scrollY > 100) {
        document.querySelector(".action-background").style.cssText +=
          "display:block;height: 75px;position: fixed;top: 85px;backdrop-filter: blur(5px);left: 0;width: 100%;z-index:3;background: rgba(255, 255, 255, 0.4);";
      } else {
        document.querySelector(".action-background").style.cssText +=
          "display:none;";
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    async updateAcceptedCounter() {
      let appCount = null;
      if (this.acceptedCounts > 0) {
        appCount = this.acceptedCounts;
      } else {
        appCount = 0;
      }
      appCount += 1;
      this.$store.state.user.acceptedCounts += 1;
      if (this.acceptedCounts === 1) {
        this.aCounter = 1;
      } else if (this.acceptedCounts % 10 === 0) {
        this.aCounter = 1;
      } else if (this.acceptedCounts % 5 === 0) {
        this.aCounter = 2;
      } else {
        this.aCounter = 0;
      }
      console.log(this.aCounter);
      console.log(this.acceptedCounts);
      let email = this.$store.state.user.email;
      let countr = {
        email: email,
        acceptedCounts: appCount,
      };
      await axios.post("/api/user/approve", countr);
    },
    commentLinks() {
      setTimeout(() => {
        var els = document.getElementsByClassName("cmnt");
        Array.prototype.forEach.call(els, function(el) {
          el.innerHTML = anchorme({
            input: el.innerHTML,
            options: {
              attributes: {
                target: "_blank",
                class: "detected",
              },
            },
          });
        });
      }, 1500);
    },
    loadCandidates() {
      this.fetchCandidates(this.$route.params.id || this.defaultCampaign);
      this.fetchCandidates2(this.$route.params.id || this.defaultCampaign);
    },
    changeVtab(number) {
      this.tab = number;
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.showMoreCounter = 0;
      this.showMoreNr = 3;
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style lang="scss">
* {
  font-family: "Nunito", sans-serif;
}
.v-tabs-slider-wrapper {
  color: transparent;
}
.v-btn {
  box-shadow: rgb(149 157 165 / 10%) 0px 8px 24px !important;
}
.purpleFade {
  background: linear-gradient(229deg, #604fe2 0%, #772276 100%);
  h1 {
    color: #fff !important;
  }
}
.syncIcon {
  left: 25px;
  position: absolute !important;
}
.syncIcon2 {
  display: none !important;
}
.v-expansion-panel-header--active {
  .v-expansion-panel-header__icon {
  }
}
.v-expansion-panel-header__icon {
  margin: 0 auto !important;
  left: 52%;
  position: absolute;
  bottom: 0;
  i {
    font-size: 2.5rem !important;
  }
}
.unicornNone {
  display: none;
}
.unicornRight {
  position: fixed;
  width: 50%;
  height: 50%;
  left: 0%;
  bottom: 40%;
  opacity: 0;
  z-index: -100;
  animation-name: unicornMoveRight;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
}
.unicornUp {
  position: fixed;

  width: 50%;
  height: 50%;
  right: 20%;
  opacity: 0;
  z-index: -100;
  animation-name: unicornMoveUp;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
}
.unicornRating {
  position: fixed;
  width: 35%;
  height: 35%;
  right: 20%;
  opacity: 0;
  z-index: -100;
  animation-name: unicornMoveUpRotate;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
}
@keyframes unicornMoveUp {
  from {
    bottom: 0;
    opacity: 1;
    z-index: 99;
  }
  20% {
    bottom: 20%;
    opacity: 1;
  }
  40% {
    bottom: 40%;
    opacity: 1;
  }
  60% {
    bottom: 50%;
    opacity: 1;
  }
  80% {
    bottom: 70%;
    opacity: 1;
  }
  100% {
    bottom: 90%;
    opacity: 0;
    z-index: 99;
  }
}
@keyframes unicornMoveUpRotate {
  from {
    bottom: 0;
    opacity: 1;
    z-index: 99;
  }
  20% {
    bottom: 20%;
    opacity: 1;
    transform: rotate(5deg);
  }
  40% {
    bottom: 50%;
    opacity: 1;
  }
  60% {
    bottom: 50%;
    opacity: 1;
    transform: rotate(-5deg);
  }
  80% {
    bottom: 60%;
    opacity: 1;
  }
  100% {
    bottom: 90%;
    opacity: 0;
    z-index: 99;
  }
}
@keyframes unicornMoveRight {
  from {
    left: -20%;
    opacity: 1;
    z-index: 99;
  }
  20% {
    left: 20%;
    opacity: 1;
  }
  40% {
    left: 40%;
    opacity: 1;
  }
  60% {
    left: 50%;
    opacity: 1;
  }
  80% {
    left: 60%;
    opacity: 1;
  }
  100% {
    left: 90%;
    opacity: 0;
    z-index: 99;
  }
}

.theme--light {
  a {
    color: black !important;
  }
  .v-pagination {
    .v-pagination__item {
      background: none !important;
      -webkit-box-shadow: 0 0 0 1px #fff !important;
      box-shadow: 0 0 0 1px white !important;
    }
    .v-pagination__item--active {
      background-color: white !important;
      color: #6353fa !important;
      -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-pagination__navigation {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      background: none !important;
      .v-icon {
        color: white !important;
      }
    }
  }
}
.v-expansion-panel {
  background-color: transparent !important;
  z-index: 0;
  .v-expansion-panel-header {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    font-size: 1.5em;
    align-items: flex-start;
    padding: 25px 24px !important;
    span {
      opacity: 0.6;
    }
  }
  &:before {
    box-shadow: none !important;
  }
}
.actionButtons {
  display: flex;
  button {
    margin-bottom: 5px;
  }
}
.v-list-item__title {
  word-break: break-word;
}
a {
  word-break: break-all;
}
.v-tabs-bar {
  position: sticky;
  top: 0;
  z-index: 3;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
    z-index: -1;
  }
}
.v-navigation-drawer__content {
  &:last-child {
    justify-self: flex-end;
  }
}
.hideChilds {
  display: flex;
  flex-direction: column;
  div {
    align-self: flex-start;
    white-space: normal;
    span {
      .question {
        opacity: 0.6;
        white-space: normal;
      }
      div {
        font-size: 20px;
      }
    }
  }
}
.mailTextInput {
  float: left;
  width: 100%;
  height: 300px;
  outline: none;
  resize: none;
  padding: 5px;
  @media only screen and (max-height: 900px) {
    height: 150px;
  }
}
.PreviewBox {
  display: grid;
  button {
    justify-self: end;
  }
}
.mailSendingDialog {
  overflow: hidden;
  border-radius: 10px;
  height: 80%;
  background: #fff;
}

.sendToCol {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: auto;
  max-height: 700px;
  margin-top: 3.8%;
}
.sendToCol::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.theme--light.v-navigation-drawer {
  background-color: #424242;

  i,
  p,
  h1,
  button {
    color: #ffffff;
  }
  .v-list-item__title {
    color: #ffffff;
  }
  .v-list-item__content {
    color: #ffffff;
  }
  .v-list-item--active {
    .v-list-item__title {
      color: rgb(99, 83, 250);
    }
  }
}
.v-snack__content {
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
}

.snackAccept {
  .v-snack__wrapper {
    min-width: 200px;
    max-width: 100px;
  }
}
.snackDecline {
  .v-snack__wrapper {
    min-width: 200px;
    max-width: 100px;
  }
}
.snackCol {
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
}
.snackCol2 {
  padding-top: 12px;
  padding-right: 25px;
  padding-bottom: 12px;
  padding-left: 0px;
}
.theme--light.v-application {
  overflow: hidden;
  background-color: #fafcfe;
  #darkCard {
    border-radius: 3em;
    margin-bottom: 10px;
    background-color: #ffffff;
    padding: 5%;
    button {
      width: 87px;
    }
    .v-list-item--two-line {
      padding: 0 15px !important;
    }
    .statusBtn {
      background-color: #575757;
      color: white;
    }
    .v-card__actions {
      p {
        font-size: 16px;
      }
    }
  }
  .v-tab {
    &::before {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }
  }
}
.snackbar {
  text-align: center;
}
.deleteButton {
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
}
#SentCard {
  .v-expansion-panels {
    .v-expansion-panel-header {
      .v-expansion-panel-header__icon {
        .v-icon {
          margin-left: 36px;
        }
      }
    }
  }
}
.mailClose {
  box-shadow: none !important;
}
.theme--light {
  .v-tabs-bar {
    background-color: transparent !important;
    &:before {
      background-color: transparent !important;
    }
  }
  .sentMailFullBoxTitle {
    background-color: #eeeeee;
  }
  .commentLine {
    color: #6353fa;
  }
  .commentIcon {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    margin-bottom: 6%;
    margin-right: 6%;
    color: #6353fa;
  }
  .commentIconNone {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 6%;
    z-index: 2;
    margin-right: 6%;
    color: black;
  }
  .deleteCommentBtn {
    color: #6353fa;
    position: relative;
    background-color: transparent;
    border: 1px solid #6353fa;
  }
  .addCommentBtn {
    color: #ffffff;
    border-color: #6353fa;
    background-color: #6353fa;
  }
  .closeComment {
    border-color: #ffffff;
    margin-left: 28px;
    margin-bottom: 20px;
    position: relative;
  }
  .sentMailFullBox {
    padding-left: 2%;
    padding-right: 5%;
    .red--text,
    .green--text {
      padding-left: 8px;
    }
    .col-3 {
      background-color: #ffffff;
      padding: 0 20px;
      border-radius: 35px;
      max-height: 35.5vh;
      h5 {
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
      }
    }
    .v-card {
      box-shadow: none !important;
    }
    .mailClose {
      justify-self: right;
      margin-left: 130px;
      width: auto;
    }
    .sendBtn {
      border-radius: 50px;
      background-color: #6353fa !important;
      color: #ffffff;
      width: 500px;
    }
    .nameBtn {
      background-color: transparent;
      font-size: 80%;
    }
    .primary {
      color: #ffffff;
      border-radius: 15%;
    }
    .mailTextInput {
      border-radius: 7px;
      background-color: #eeeeee;
    }

    .PreviewBox {
      .mailTextInput {
        background-color: #ffffff;
      }
    }
  }
  .v-list-item--active {
    color: white;
  }
  .v-dialog--active {
    .candidateBox {
      background-color: #dfdff7;
      margin: 15px 0;
    }
  }

  .emailSent {
    padding: 5%;
    .v-icon {
      color: #6353fa;
    }
  }
}
.theme--dark {
  a {
    color: white;
  }
  .v-pagination {
    .v-pagination__item {
      background: none !important;
      -webkit-box-shadow: 0 0 0 1px #424242 !important;
      box-shadow: 0 0 0 1px #424242 !important;
    }
    .v-pagination__item--active {
      background-color: #424242 !important;
      color: #fa98cb !important;
      -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-pagination__navigation {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      background: none !important;
      .v-icon {
        color: white !important;
      }
    }
  }
}
.theme--dark.v-tabs-items {
  background-color: transparent !important;
  .theme--dark.v-card {
    background-color: transparent;
    padding: 5%;
  }
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
  .theme--light.v-card {
    background-color: transparent;
    padding: 5%;
  }
}
.v-tabs-bar {
  height: 60px;
}
.theme--dark {
  .v-tabs-bar {
    &:before {
      background-color: #4f4f4f !important;
    }
  }
  .commentLine {
    color: #fa98cb;
  }
  .commentIcon {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 10%;
    margin-right: 6%;
    color: #fa98cb;
  }
  .commentIconNone {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 6%;
    margin-right: 6%;
    color: white;
    z-index: 2;
  }
  .deleteCommentBtn {
    color: white;
    position: relative;
    background-color: #ff8e8e;
  }
  .addCommentBtn {
    color: white;
    background-color: black;
  }
  .closeComment {
    border-color: #4f4f4f;
    margin-left: 28px;
    margin-bottom: 20px;
    position: relative;
  }
  .mailAndDownloadBtn {
    border-color: #fa98cb;
    color: #fa98cb;
  }
  .sentMailFullBoxTitle {
    background-color: #303030;
  }
  .sentMailFullBox {
    padding-left: 5%;
    padding-right: 5%;
    .red--text,
    .green--text {
      padding-left: 8px;
    }
    .col-3 {
      background-color: #424242;
      padding: 0 20px;
      max-height: 35.5vh;
      border-radius: 35px;
      h5 {
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #424242;
      }
    }
    .v-card {
      background-color: #424242;
      box-shadow: none;
    }
    .v-btn {
      color: #fa98cb;
    }
    .sendBtn {
      border-radius: 50px;
      background-color: #ff7bac !important;
      color: #ffffff;
      width: 500px;
    }
    .nameBtn {
      background-color: transparent;
      font-size: 80%;
    }
    .primary {
      color: #fa98cb;
      border-radius: 15%;
      color: #ffffff;
    }
    .mailTextInput {
      border-radius: 7px;
      background-color: #303030;
    }

    .PreviewBox {
      .mailTextInput {
        background-color: transparent;
      }
    }
  }
  .v-dialog--active {
    background-color: #424242;
  }
  #darkCard {
    border-radius: 3em;
    margin-bottom: 10px;
    background-color: #424242;
    padding: 5%;
    button {
      width: 87px;
    }
    .v-list-item--two-line {
      padding: 0 15px !important;
    }
    .statusBtn {
      background-color: #676767;
      color: white;
    }
    .v-card__actions {
      p {
        font-size: 16px;
      }
    }
  }
  #SentMail {
    background-color: #545454;
  }
  .v-tab--active {
    background: rgba(250, 152, 203, 0.3);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .v-tab {
    &::before {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }
  }
}
.theme--dark.v-application {
  background: #4f4f4f;
}
.btnPosition {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 1.5%;
  margin-right: 10%;
  button {
    margin-right: 2%;
  }
  .circleCol {
    display: flex;
    flex-direction: column;
    display: inline-block;
    max-width: 150px;
    margin-left: 3%;
  }
  .circleNr {
    color: #5ab55e;
    font-size: 30px;
    margin-top: 40%;
    margin-left: 5px;
    font-weight: bold;
    display: inline-block;
  }
  .dividerCol {
    display: flex;
    flex-direction: column;
    max-width: 20px;
    display: inline-block;
  }
}
.numbersCol {
  display: inline-block;
  display: flex !important;
  flex-direction: column;
  max-width: 200px;
  align-items: flex-start;
  margin-left: 1.6%;
}
.emailSent {
  text-align: center;
  position: absolute;
  right: 10%;
  top: 0;
  p {
    text-align: center;
    font-weight: bold;
  }
  .v-icon {
    color: rgb(250, 152, 203);
    font-size: 80px;
    line-height: 1.1rem;
  }
}
.singleTab {
  .v-icon {
    font-size: 33px;
  }
}
@media only screen and (max-width: 600px) {
  .v-card__actions {
    flex-direction: column;
  }
  .syncIcon {
    display: none !important;
  }
  .syncIcon2 {
    display: block !important;
  }
  #SentCard,
  #darkCard {
    .v-expansion-panel-header__icon {
      left: 42%;
    }
    .v-expansion-panels {
      .v-expansion-panel-header {
        padding: 45px 24px !important;
        .v-expansion-panel-header__icon {
          left: 42% !important;
          .v-icon {
            margin-left: 0;
          }
        }
      }
    }
  }
  .closeComment {
    position: absolute !important;
    margin-left: 0 !important;
    left: 0;
  }
  .numbersCol {
    p {
      font-size: 16px !important;
    }
  }
  .statusBtn,
  .v-btn--disabled,
  .mailAndDownloadBtn {
    margin-bottom: 10px;
  }
  .v-dialog {
    margin: 0 !important;
  }
  .col {
    padding-left: 0 !important;
  }
  .sentMailFullBox {
    .sendBtn {
      margin: 0 auto !important;
      width: 50% !important;
      position: fixed;
      bottom: 20px;
      z-index: 2;
    }
    .col-5,
    .col-4 {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0 20px;
      margin-left: 10px;
    }
  }
  .btnPosition {
    flex-direction: column;
    margin-right: 0;
  }
  .hideChilds {
    .v-list-item__content {
      word-break: break-all;
    }
    div {
      span {
        div {
          font-size: 16px;
        }
      }
    }
  }
  .question {
    word-break: break-all;
  }

  .singleTab {
    h1 {
      font-size: 10px;
      display: flex;
      flex-direction: column;
    }
  }
  .emailSent {
    position: relative;
    padding-bottom: 10px;
  }
  .col-3 {
    display: none;
  }
}
.cmt {
  word-wrap: break-word;
}
.candidateBox {
  background-color: #4f4f4f;
  text-align: center;
  border-radius: 7px;
}
.mailSentSelectBox {
  .bottomColor {
    width: 100%;
    height: 20px;
    background: #6353fa;
    opacity: 70%;
  }
  button {
    &:first-child {
      border: 2px solid;
      margin-top: 5%;
      margin-left: 90%;
    }
  }
}
.commentBox {
  padding-bottom: 0;
  padding-left: 0;
  overflow: hidden;
  .bottomColor {
    width: 110%;
    height: 20px;
    background: #6353fa;
    opacity: 70%;
  }
}
.mailDialogWithBell {
  overflow: visible !important;
  box-shadow: none !important;
  border-radius: 10px;
  border: 1px solid #bababa3c;
}
.v-card {
  box-shadow: rgb(149 157 165 / 10%) 0px 8px 24px !important;
}
.v-list-item--link {
  border-radius: 0 !important;
}
.v-data-table-header {
  background-color: #f4f5f6 !important;
}
.sentStatusBackground {
  background: rgba(246, 246, 246, 0.4);
}
</style>
