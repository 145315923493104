<template>
  <div
    class="animContainer"
    v-if="animDialog"
    v-custom-click-outside="onClickOutside"
  >
    <div v-if="animationType === 1 && animDialog">
      <lottie-animation
        ref="anim"
        :animationData="require('@/lotties/unicorn.json')"
      ></lottie-animation>
    </div>
    <div v-else-if="animationType === 2 && animDialog">
      <lottie-animation
        ref="anim"
        :animationData="require('@/lotties/hearthOnHorn.json')"
      ></lottie-animation>
    </div>
    <div v-else-if="animationType === 3 && animDialog">
      <lottie-animation
        ref="anim"
        :animationData="require('@/lotties/popcorn.json')"
      ></lottie-animation>
    </div>
    <div v-else-if="animationType === 5 && animDialog">
      <lottie-animation
        ref="anim"
        :animationData="require('@/lotties/sad.json')"
      ></lottie-animation>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import Vue from "vue";

Vue.directive("custom-click-outside", {
  bind: function(element, binding, vnode) {
    element.clickOutsideEvent = function(event) {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", element.clickOutsideEvent);
  },
  unbind: function(element) {
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
});

export default {
  name: "Animation",
  components: {
    LottieAnimation,
  },
  data() {
    return {
      animDialog: false,
    };
  },
  computed: {
    ...mapState(["animation", "animationType"]),
  },
  methods: {
    closeDialog() {
      setTimeout(() => {
        this.animDialog = false;
        //this.$refs.anim.stop()
      }, 3000);
    },
    onClickOutside() {
      this.animDialog = false;
    },
  },
  created() {
    //this.animDialog = true;
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "START_ANIMATION") {
        this.animDialog = true;
        this.closeDialog();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.vdialognew {
  box-shadow: none !important;
}
.animationFrame {
  border: none;
  a {
    display: none;
  }
}
.animContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
}
</style>
