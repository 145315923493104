<template>
  <v-container fluid class="cont">
    <div class="top" v-scroll="handleScroll">
      <v-row class="top-row">
        <v-btn
          tile
          :class="this.$route.name === 'profileView' ? 'active-nav--item' : ''"
          @click.prevent="changeView('profileView')"
        >
          Campaign View
        </v-btn>
        <v-btn
          tile
          :class="this.$route.name === 'campaignView' ? 'active-nav--item' : ''"
          @click.prevent="changeView('campaignView')"
        >
          Profile View
        </v-btn>
        <v-btn
          tile
          :class="this.$route.name === 'listView' ? 'active-nav--item' : ''"
          @click.prevent="changeView('listView')"
        >
          List View
        </v-btn>
      </v-row>
    </div>

    <div style="height: 100px"></div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});
export default {
  name: "DashboardNav",
  methods: {
    ...mapActions(["setError"]),
    handleScroll: function () {
      if (window.scrollY > 100) {
        document.querySelector(".top").style.cssText += "z-index:7;top:135px;";
      } else {
        document.querySelector(".top").style.cssText += "top:100px;";
        // document.querySelector(".buttons").style.top = "150px";
        // document.querySelector(".buttons").style.width = "auto";
        // document.querySelector(".buttons").style.background = "transparent";
      }
    },
    async changeView(viewName) {
      if (viewName !== this.$route.name) {
        this.$router.push({ name: viewName });
      } else {
        this.setError("You already are at this view.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cont {
  position: relative;
}
.top {
  position: fixed;
  top: 100px;
  width: 100%;
  max-width: 500px;
  margin-left: 10px;
}
.v-btn {
  font-weight: bold;
  color: #707070;
  height: 31px;
  box-shadow: none;
  background-color: #f7f6f6;
  text-transform: none;
  width: 100%;
  max-width: 150px;
}
.active-nav--item {
  background-color: #fa98cb !important;
  color: #fff;
  height: 38px !important;
}
</style>
